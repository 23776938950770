<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header justify-content-between">
					<div class="input-group input-group-flush d-flex flex-row-reverse">
						<input
							v-model.trim="search"
							class="form-control list-search"
							type="search"
							placeholder="Search"
							/>
						<span class="input-group-text border-0">
							<i class="fe fe-search"></i>
						</span>
					</div>
					<div class="">
						<!-- <download-button
							v-if="events.length"
							@downloading="downloadingReport"
							label="Download Report"
							@download="downloadReport()"
							></download-button> -->
					</div>
				</div>
				<div class="col px-0 d-flex my-3">
					<div class="col"></div>
					<div class="col-auto">
						<div class="col-auto" v-if="events.length">
							<v-datepicker
								style="width: 100%"
								v-model="dateRangeFilter"
								placeholder="Today"
								range
								></v-datepicker>
						</div>
					</div>
				</div>
				<b-table
					striped
					hover
					selectable
					show-empty
					responsive
					:items="events"
					:fields="fields"
					:current-page="currentPage"
					:busy="fetchingEvents"
					@row-clicked="viewEvents"
					>
					<template #table-busy>
						<div
							class="d-flex flex-column justify-content-center align-items-center"
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>
					<template #empty>
						<p class="text-center text-secondary">No records available</p>
					</template>
					<template #cell(title)="data">
						<p class="my-0" style="line-height: 1">
							<router-link
								class="sh-page-link text-nowrap"
								:to="{
									name: 'ShowEventInfo',
									params: { eventId: data.item.id },
								}"
								>{{ data.item.title }}</router-link
								>
						</p>
					</template>
					<template #cell(user)="data">
						<div>
							<p class="font-weight-bold my-3" style="line-height: 1">
								<router-link
									class="sh-page-link d-flex"
									:to="{
										name: 'ShowEventInfo',
										params: { eventId: data.item.id },
									}"
									>
									<span>{{
										`${data.item.firstName} ${data.item.lastName}`
									}}</span>
								</router-link>
							</p>
							<p class="my-3" style="line-height: 1">
								{{ data.item.email }}
							</p>
						</div>
					</template>
					<template #cell(createdAt)="data">
							<p class="text-nowrap my-0" style="line-height: 1">
								{{ formatDate(data.item.createdAt) }}
							</p>
					</template>
					<template #cell(trip_type)="data">
						<p class="text-nowrap my-0" style="line-height: 1"
            >{{ data.item.returnTrip ? 'Return' : 'One-way' }}</p>
					</template>
					<template #cell(attendeesEstimate)="data">
						{{ data.item.attendeesEstimate }}
					</template>
					<template #cell(location)="data">
						<div class="route__destination locate">
							<img class="icon" src="@/assets/img/destination_point.svg" />
							<p class="my-0">{{ data.item.location }}</p>
						</div>
					</template>
					<template #cell(status)="data">
						<span v-for="status in eventListStatus" :key="status">
							<div
								v-if="data.item.status == status"
								:class="`status status-${status}`"
								>
								{{ status }}
							</div>
						</span>
					</template>
				</b-table>
        
			</div>
		</div>
		<div class="pageFooter" v-if="!fetchingEvents">
			<section>
				<p class="desc">Showing {{ paginationStartIndex }} - {{ paginationEndIndex }} of {{ totalRecords }}</p>
			</section> 
			<section>
				<b-pagination
					v-model="currentPage"
					:total-rows="totalRecords"
					:per-page="perPage"
					></b-pagination>
			</section>
		</div>
	</div>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'
import moment from 'moment'
import DownloadButton from '@/components/core/DownloadButton.vue'
import _ from 'lodash'
import eventServiceInstance from '@/api/event_service'

export default {
  components: {
    DownloadButton
},
  data() {
    return {
      currentPage: 1,
      metadata: {},
      eventListStatus: [
        'pending',
        'accepted',
        'queried',
        'rejected',
        'cancelled',
      ],
      fields: [
        {
          key: 'title',
          label: 'Event Title',
        },
        {
          key: 'user',
          label: 'Customer Name',
        },
        {
          key: 'createdAt',
          label: 'Date',
        },
        {
          key: 'location',
          label: 'Event Location',
        },
        {
          key: 'trip_type',
          label: 'Trip Type',
        },
        {
          key: 'attendeesEstimate',
          label: 'No of Attendees',
        },
        {
          key: 'status',
          label: 'Status',
        },
      ],
      perPage: 10,
      totalRecords: 0,
      totalPages: 0,
      pageSize: 0,
      events: [],
      allEvents: [],
      loading: false,
      fetchingEvents: true,
      search: '',
      processing: false,
      statusFilter: 'active',
      downloadingReport: false,
      dateRangeFilter: [],
      filter: {
        dateRange: [],
      },
      statusBar: ['Active', 'Inactive', 'Suspend'],
    }
  },
  created() {
    this.getAllEvents()
    this.debouncedCallback = _.debounce(() => {
      this.currentPage = 1
      this.getAllEvents()
    }, 1000)
  },
  computed: {
    paginationStartIndex() {
      return (this.currentPage * this.perPage) - (this.perPage - 1)
    },
    paginationEndIndex() {
      return (this.paginationStartIndex - 1) + this.pageSize
    },
  },
  watch: {
    currentPage() {
      this.getAllEvents()
    },

    search() {
      this.debouncedCallback()
    }, 

    statusFilter() {
      this.getAllEvents()
    },

    dateRangeFilter() {
      this.getAllEvents()
    }
  },
  methods: {
    formatDate(d) {
      return moment(d).format('MMM Do YYYY')
    },

    async getAllEvents() {
      this.fetchingEvents = true

      try {
        const res = await eventServiceInstance
        .getEvents(
          this.currentPage,
          this.perPage,
          this.dateRangeFilter[0],
          this.dateRangeFilter[1],
          this.search,
          undefined
        )
        if ([200, 201].includes(res.status)) {
          this.events = res.data.data
          this.metadata = res.data.metadata
          this.currentPage = res.data.metadata.currentPage
          this.totalRecords = res.data.metadata.count
          this.totalPages = res.data.metadata.pageCount
          this.pageSize = res.data.metadata.pageSize
        } 
      } catch (e) {
        this.$toastr.e('Something went wrong', 'Error')
      } finally {
        this.fetchingEvents = false
      }
    },

    downloadReport() {
      this.downloadingReport = true
      const csvData = this.events.map((data) => {
        const eventRoutes = data.routes_assigned.map((eachEvent) => {
          return eachEvent
        })
        return {
          name: data.fname + ' ' + data.lname,
          average_rating: data.average_rating,
          phone_number: data.phone_number,
          routes_assigned: eventRoutes,
          status:
            data.active == 1
              ? 'Active'
              : data.active == 0
              ? 'Inactive'
              : 'suspended',
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        filename: !this.dateRange.length
          ? 'Drivers List'
          : `Drivers List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: !this.dateRange.length
          ? 'Drivers List'
          : `Drivers List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        useTextFile: false,
        useBom: true,
        headers: [
          'Name',
          'Average Rating',
          'Phone',
          'Routes assigned',
          'Status',
        ],
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)

      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true,
      })
    },

    viewEvents(event) {
      this.$router.push({
        name: 'ShowEventInfo',
        params: { eventId: event.id },
      })
    }
  },
  beforeDestroy() {
    this.debouncedCallback.cancel()
  }
}
</script>
<style lang="scss" scoped>
@use 'src/assets/scss/b-table';
@use 'src/assets/scss/partials/_badges.scss';

// .link {
//   text-decoration: none;
//   color: #fff;
// }
// .date {
//   display: flex;
//   align-items: center;
// }
.locate {
  width: 184px;
  height: 42px;
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;

  // .text {
  //   padding: 0px !important;
  //   white-space: nowrap !important; 

  //   &.dest {
  //     white-space: nowrap !important;
  //   }
  // }
  // .icon {
  //   height: 1.2rem;
  // }
}
.route {
  padding: 0 0.25rem;
  background: transparent;
  font-weight: 400;
  font-size: 12px;
  max-width: 100%;

  &__source {
    position: relative;
    &:before {
      border-left: 1px dashed #a3a3a3;
      position: absolute;
      top: 1.25rem;
      left: 0.28125rem;
      height: 70%;
      content: '';
      z-index: 0;
    }
  }

  &__source,
  &__destination {
    display: flex;
    flex-wrap: nowrap;
    color: #313533;
    .icon {
      margin-right: 0.5rem;
      width: 0.8rem;
      z-index: 2;
    }
    .text {
      /*      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;*/

      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      padding: 1.5rem 0;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap !important;

      //  Container has to have a max-width attribute
    }
  }
}

.pageFooter {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 94px;

    & .desc{ 
      font-size: 14px;
      font-weight: 500;
      color: #131615;
    }
  }
</style>
